import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalCommService } from '../../services/global-comm.service';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../../CRUD/Settings';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { SessionLoginService } from '../../services/session/session-login.service';

@Component({
  selector: 'app-report-global-settings',
  templateUrl: './report-global-settings.component.html',
  styleUrls: ['./report-global-settings.component.css']
})
export class ReportGlobalSettingsComponent implements OnInit, OnDestroy {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  itemSchema: any = {};

  constructor(public dialog: MatDialog, private globalcom: GlobalCommService) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
 }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'open-global-repot-settings':
          this.openDialog();
          break;
        default: break;
      }
    }
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogGlobalReportSettingsDialogComponent, {
      width: '950px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.animal = result;
    });
  }

}

@Component({
  selector: 'app-dialog-global-report-settings-dialog',
  templateUrl: 'dialog-global-report-settings-dialog.html',
  styleUrls: ['./dialog-global-report-settings-dialog.component.css']
})
export class DialogGlobalReportSettingsDialogComponent implements  OnInit, OnDestroy {

  predefOptions: string[] = ['m²', 'm', 'm1', 'per stuk', 'per app.'];

  coverup: boolean;
  resetType: string;

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  settings: Settings;
  data: any;

  isLoading: boolean = false;

  constructor(
    private http: HttpClient, private route: ActivatedRoute, private router: Router,
    private session: SessionLoginService,
    public dialogRef: MatDialogRef<DialogGlobalReportSettingsDialogComponent>,
    private globalcom: GlobalCommService) {
      this.coverup = false;
      this.resetType = '';

      this.globalcomInit = true;
      this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
        this.globalcomMsg = message;
        if ( !this.globalcomInit ) { this.parseGlobalCom(); }
        this.globalcomInit = false;
      });

      this.settings = new Settings(this.http, this.router, this.globalcom, this.session);
  }

  parseGlobalCom(): void {
  }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.settings.get('structureJSON').subscribe(
      (res: any) => {
        if ('ind' in res) {
          this.isLoading = false;
          const jData = JSON.parse(this.settings.jsonData);
          this.data = jData;
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.settings.jsonData = JSON.stringify(this.data);
    this.isLoading = true;
    this.settings.set().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  resetStartDefault(type: string): void {
    this.coverup = true;
    this.resetType = type;
  }
  resetClose(): void {
    this.coverup = false;
    this.resetType = '';
  }

  addGE(item: any): void {
    item.push({key: '', name: '', action: '', unit: ''});
  }

  deleteG(item: any): void {
    const index = this.data.elements.indexOf(item);
    if ( index !== -1 ) {
      this.data.elements.splice(index, 1);
    }
  }

  deleteL(item: any, item1: any): void {
    const index = item.indexOf(item1);
    if ( index !== -1 ) {
      item.splice(index, 1);
    }
  }

}
