import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportMainViewComponent } from './report/report-main-view/report-main-view.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MainViewComponent } from './main-view/main-view.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'main',
    component: MainViewComponent
  },
  {
    path: 'r/:id',
    component: ReportMainViewComponent
  },
  {
    path: '**', redirectTo: '/login', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
