import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share, shareReplay } from 'rxjs/operators';
import {Router} from '@angular/router';
import {ErrorHandler} from './ErrorHandler';
import { GlobalCommService } from '../services/global-comm.service';
import { SessionLoginService } from '../services/session/session-login.service';

export class Settings {
    ind: string;
    jsonData: string;

    error: boolean;
    errors: any[] = [];

    isWorking: any;
    typeOfWork: string;

    usableFields: any = {
        ind: 'ind',
        json_data: 'jsonData'
    };

    constructor(private http: HttpClient, private router: Router,
                private globalComm: GlobalCommService,
                private session: SessionLoginService) {
        this.ind = '';
        this.jsonData = '{}';

        this.isWorking = false;
        this.typeOfWork = '';
        this.error = false;
    }

    reload(): Observable<any> {
        return this.get(this.ind);
    }

    reloadSelected(sel: any[]): Observable<any> {
        return this.get(this.ind, sel);
    }

    get(ind: string, sel?: any[]): Observable<any> {
        if ( this.isWorking !== false ) { return this.isWorking; }
        this.typeOfWork = 'load';
        this.ind = ind;
        const rv = this.http.get('/API/settings/' + encodeURIComponent(this.ind) + '/',
                                 { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        rv.subscribe(
            (res: any) => {
                if ('ind' in res) {
                    this.error = false; this.errors = [];
                    if ( sel ) {
                        for (const key of sel) {
                            if (key in this.usableFields && key in res) {
                                this[this.usableFields[key]] = res[key];
                            } else {
                                console.log('Cant map Settings.' + key);
                            }
                        }
                    } else {
                        for (const key in res) {
                            if (key in this.usableFields) {
                                this[this.usableFields[key]] = res[key];
                            } else {
                                console.log('Cant map Settings.' + key);
                            }
                        }
                    }
                } else if ('length' in res && res.length === 0) {
                    this.router.navigate(['404-element-not-found']);
                } else if ('error' in res ) {
                    const eh = new ErrorHandler(this.router);
                    eh.handle(res.error);
                } else {
                    this.error = true; this.errors.push('Cannot load Settings: ' + this.ind);
                }
            },
            () => { this.isWorking = false; this.error = true; this.errors.push('Cannot load Settings: ' + this.ind); },
            () => { this.isWorking = false; }
        );
        return rv;
    }

    set(): Observable<any> {
        if ( this.isWorking !== false ) { return this.isWorking; }
        this.typeOfWork = 'save';
        const toSend = {};
        for (const fk of Object.keys(this.usableFields)) {

            toSend[fk] = this[this.usableFields[fk]];
        }
        let URL = '/API/settings/';
        if ( this.ind !== '' ) { URL += encodeURIComponent(this.ind) + '/'; }
        this.isWorking = this.http.post(URL, toSend, { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        this.isWorking.subscribe(
            (res: any) => {
                if ('ind' in res) {
                    this.error = false; this.errors = [];
                    for (const key in res) {
                        if (key in this.usableFields) {
                            this[this.usableFields[key]] = res[key];
                        } else {
                            console.log('Cant map Settings.' + key);
                        }
                    }
                } else {
                    this.error = true; this.errors.push('Cannot save Settings.');
                }
                this.isWorking = false;
            },
            () => { this.isWorking = false; this.error = true; this.errors.push('Cannot save Settings.'); },
            () => { this.isWorking = false; }
        );
        return this.isWorking;
    }

}
