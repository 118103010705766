import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCommService } from '../../services/global-comm.service';

@Component({
  selector: 'app-report-section-yearly-costs',
  templateUrl: './report-section-yearly-costs.component.html',
  styleUrls: ['./report-section-yearly-costs.component.css']
})
export class ReportSectionYearlyCostsComponent implements OnInit {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  keyName = 'yearcosts';

  itemSchema: any = {};
  @Input()
  set schema(val: any) { this.itemSchema = val; this.schemaInit(); }
  get schema(): any { return this.itemSchema; }

  itemValues: any = {};
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private globalcom: GlobalCommService) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        default: break;
        case 'start-year-changed': this.reinitAfterStartYearChange();
        break;
      }
    }
  }

  public ngOnInit(): void {
    this.initValuesStructure();
  }

  schemaInit(): void {
    this.initValuesStructure();
  }

  valuesInit(): void {
    this.initValuesStructure();
  }

  reinitAfterStartYearChange(): void {
    if ( typeof this.itemSchema['elements'] !== 'undefined' && typeof this.itemSchema['elements'] ) {
      for (let i = 0; i < this.itemSchema['elements'].length; i++ ) {
        for (let j = 0; j < this.itemSchema['elements'][i]['childs'].length; j++ ) {
          this.recalculateUrgency(this.itemSchema['elements'][i]['childs'][j]);
        }
      }
    }
  }

  recalculateUrgency(item1: any): void {
    let item = this.itemValues.yearCosts[item1.key];
    if (item.cycleenddate && item.cycle &&
        !isNaN(parseFloat(item.cycleenddate)) &&
        !isNaN(parseFloat(item.cycle)) &&
        this.itemValues.startYear &&
        !isNaN(parseFloat(this.itemValues.startYear)) ) {
      const cycleenddate = parseInt(item.cycleenddate);
      const cycle = parseInt(item.cycle);
      const startYear = parseInt(this.itemValues.startYear);
      if ( cycleenddate == 0 || cycle == 0 || startYear == 0 ) {
        return;
      }
      let jYear = cycleenddate;
      while(jYear < startYear) {
        jYear += cycle;
      }
      if (jYear-startYear == 0) {
        item.urgency = 1;
      } else
      if (jYear-startYear < 5) {
        item.urgency = 2;
      } else
      if (jYear-startYear < 10) {
        item.urgency = 3;
      } else {
        item.urgency = 4;
      }
      item.urgency_printable = this.getUrgencyName(item.urgency);
      console.log('rr', item);
    }
  };

  getUrgencyName(id: number): string {
    switch(id){
      default: return ''; break;
      case 1: return 'zeer urgent'; break;
      case 2: return 'urgent'; break;
      case 3: return 'tamelijk urgent'; break;
      case 4: return 'niet urgent'; break;
    }
    return '';
  }

  modelChanged($event: any, item: any, urgency: string = ''): void {
    this.recalculateLine(item);
    if ( urgency == 'u' ) {
      this.recalculateUrgency(item);
    }
    this.sendMessage();
    this.globalcom.changeMessage({
      type: 'values-changed',
      data: item
    });
  }

  numberWithCommas(x: any): string {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
  }

  fprint(val: any): string {
    const s = val.toString().split('.');
    let r = '';
    r += this.numberWithCommas(s[0]);
    if ( s[1] ) {
      r += ',';
      switch ( s[1].length ) {
        case 0: r += '00'; break;
        case 1: r += s[1] + '0'; break;
        case 2: r += s[1]; break;
        default: r += s[1].substr(0, 2); break;
      }
    } else {
      r += ',00';
    }
    return r;
  }

  recalculateLine(item: any): void {
    if ( isNaN(parseFloat(this.itemValues['yearCosts'][item.key].unitprice)) ||
         isNaN(parseFloat(this.itemValues['yearCosts'][item.key].amount)) ||
         isNaN(parseFloat(this.itemValues['yearCosts'][item.key].cycle)) ||
         parseFloat(this.itemValues['yearCosts'][item.key].cycle) === 0 ) {
         if ( this.itemValues['yearCosts'][item.key].total_printable ) {
           delete this.itemValues['yearCosts'][item.key].total_printable;
         }
         if ( this.itemValues['yearCosts'][item.key].totalpy_printable ) {
           delete this.itemValues['yearCosts'][item.key].totalpy_printable;
         }
         return;
    }
    this.itemValues['yearCosts'][item.key].total = Number(this.itemValues['yearCosts'][item.key].unitprice *
                                                   this.itemValues['yearCosts'][item.key].amount).toFixed(2);
    this.itemValues['yearCosts'][item.key].total_printable = this.fprint(this.itemValues['yearCosts'][item.key].total);
    this.itemValues['yearCosts'][item.key].totalpy = Number(this.itemValues['yearCosts'][item.key].total /
                                                     this.itemValues['yearCosts'][item.key].cycle).toFixed(2);
    this.itemValues['yearCosts'][item.key].totalpy_printable = this.fprint(this.itemValues['yearCosts'][item.key].totalpy);
    this.recalculateTotal();
  }

  recalculateTotal(): void {
    let total = 0;
    Object.keys(this.itemValues['yearCosts']).forEach((key: string) => {
      if ( this.itemValues['yearCosts'][key].totalpy ) {
        total += Number(this.itemValues['yearCosts'][key].totalpy);
      }
    });
    this.itemValues.yearCostsTotal = total;
    this.itemValues.yearCostsTotalPrintable = this.fprint(total);
  }

  initValuesStructure(): void {
    if ( typeof this.itemValues['yearCosts'] === 'undefined' ) {
      this.itemValues['yearCosts'] = {};
    }
    if ( typeof this.itemSchema['elements'] !== 'undefined' && typeof this.itemSchema['elements'] ) {
      for (let i = 0; i < this.itemSchema['elements'].length; i++ ) {
        for (let j = 0; j < this.itemSchema['elements'][i]['childs'].length; j++ ) {
          if ( typeof  this.itemValues['yearCosts'][this.itemSchema['elements'][i]['childs'][j].key] === 'undefined' ) {
            this.itemValues['yearCosts'][this.itemSchema['elements'][i]['childs'][j].key] = {cycle: '', urgency: '',
                                                                                             unitprice: '', amount: '',
                                                                                             total: 0, totalpy: 0,
                                                                                             cycleenddate: ''};
          }
          if ( this.itemSchema['elements'][i]['childs'][j].unitprice && this.itemValues['yearCosts'][this.itemSchema['elements'][i]['childs'][j].key].unitprice == '' ) {
            this.itemValues['yearCosts'][this.itemSchema['elements'][i]['childs'][j].key].unitprice = this.itemSchema['elements'][i]['childs'][j].unitprice;
          }
        }
      }
    }
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

}
