import { Component,  OnInit, OnDestroy } from '@angular/core';
import { GlobalCommService } from '../../services/global-comm.service';
import { SessionLoginService } from '../../services/session/session-login.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-report-print-popup',
  templateUrl: './report-print-popup.component.html',
  styleUrls: ['./report-print-popup.component.css']
})
export class ReportPrintPopupComponent implements OnInit, OnDestroy {

  printUrl: string = '/API/';

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  showPrint: boolean = false;

  constructor(private globalcom: GlobalCommService,
              private sanitizer: DomSanitizer) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'run-print-final-dialog':
          this.runPrintDialog(pMsg);
          console.log(pMsg);
        default: break;
      }
    }
  }

  close(): void {
    this.showPrint = false;
  }

  runPrintDialog(data: any): void {
    this.showPrint = true;
    this.printUrl = '/API/pr/'+data.rId;
  }

  getUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.printUrl);
  }

}
