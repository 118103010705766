import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-report-section-main',
  styleUrls: ['./report-section-main.component.css'],
  templateUrl: './report-section-main.component.html',
})
export class ReportSectionMainComponent implements OnInit {

  keyName = 'maindata';

  @Input() parentID: string;

  itemSchema: any = {};
  @Input()
  set schema(val: any) { this.itemSchema = val; this.schemaInit(); }
  get schema(): any { return this.itemSchema; }

  itemValues: any = {};
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  public ngOnInit(): void {
  }

  schemaInit(): void {
  }

  valuesInit(): void {
  }

  reciveEvent($event): void {
    // this.reportValues = $event.
  }

  reciveSchenaEvent($event): void {
    // this.reportValues = $event.
  }

  modelChanged($event: any): void {
    this.sendMessage();
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

}
