import { Component, OnInit, OnDestroy, DoCheck, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { GlobalCommService } from '../../services/global-comm.service';
import defaultReportSettings from './defaultReportSettings.json';
import { Report } from '../../CRUD/Report';
import { Settings } from '../../CRUD/Settings';
import { SessionLoginService } from '../../services/session/session-login.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-main-view',
  templateUrl: './report-main-view.component.html',
  styleUrls: ['./report-main-view.component.css']
})
export class ReportMainViewComponent implements OnInit, OnDestroy, DoCheck {

  differInit: boolean;
  private entityDiffer: KeyValueDiffer<string, any>;
  entity: Report;
  systemSettings: Settings;

  reportValues: any = {
    _config: {
      reportViews: 'all'
    }
  };

  isLoading: boolean;

  schematic: any = {};

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  alreadyChanged: boolean;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private globalcom: GlobalCommService,
              private differs: KeyValueDiffers, private session: SessionLoginService,
              private snackBar: MatSnackBar) {
    // this.schematic = defaultReportSettings;
    this.differInit = true;

    this.isLoading = false;

    this.entity = new Report(this.http, this.router, this.globalcom, this.session);
    this.systemSettings = new Settings(this.http, this.router, this.globalcom, this.session);

    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'reset-to-system':
          this.resetToSystemSettings();
          break;
        case 'reset-to-hardreset':
          this.schematic = defaultReportSettings;
          this.snackBar.open('Het terugzetten van de elementen naar de oorspronkelijke basis instelling is gedaan.', '', {
            duration: 2000,
          });
          break;
        case 'reset-to-new-setup':
          this.schematic = pMsg.settings;
          this.snackBar.open('Het terugzetten van de elementen instelling is gedaan.', '', {
            duration: 2000,
          });
          break;
        case 'run-print':
          let url = '/API/pr/' + this.entity.ind;
          if ( pMsg.concept ) {
            url += '?c=t';
          }
          window.open(url,'_blank');
          this.snackBar.open('Controleer afdrukken in nieuw pop-upvenster.', '', {
            duration: 2000,
          });
          // this.globalcom.changeMessage({
          //   type: 'run-print-final-dialog',
          //   concept: pMsg.concept,
          //   rId: this.entity.ind
          // });
          break;
        default: break;
      }
    }
  }

  ngOnInit(): void {
    this.entityDiffer = this.differs.find(this.entity).create();
    if ( !this.session.isLogged() ) {
      this.router.navigate(['login']);
      return;
    }
    this.route.params.subscribe((params: any) => {
      if (params.id !== 'new') {
        this.isLoading = true;
        this.entity.get(params.id).subscribe(
          (res: any) => {
            if ('ind' in res) {
              this.isLoading = false;
              const jData = JSON.parse(this.entity.jsonData);
              this.schematic = jData.schematic;
              this.reportValues = jData.values;
              this.reportValues._config.reportViews = 'all';
              this.entityDiffer = this.differs.find(this.entity).create();
            }
          }
        );
      } else {
        this.systemSettings.get('structureJSON').subscribe(
          (res: any) => {
            if ('ind' in res) {
              const jData = JSON.parse(this.systemSettings.jsonData);
              this.schematic = jData;
              this.entityDiffer = this.differs.find(this.entity).create();
            }
          }
        );
      }
    });
  }

  resetToSystemSettings(): void {
    this.systemSettings.get('structureJSON').subscribe(
      (res: any) => {
        if ('ind' in res) {
          const jData = JSON.parse(this.systemSettings.jsonData);
          this.schematic = jData;
          this.snackBar.open('Het terugzetten van de elementen naar de standaard instelling is gedaan.', '', {
            duration: 2000,
          });
        }
      }
    );
  }

  reciveEvent($event): void {
    // this.reportValues = $event.
  }

  reciveSchenaEvent($event): void {
    // this.reportValues = $event.
  }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }

  ngDoCheck(): void {
    if ( typeof this.entityDiffer === 'undefined' ) { return; }
    const changes = this.entityDiffer.diff(this.entity);
    if (changes) {
      this.dataObChanged(changes);
    }
  }

  dataObChanged(changes: KeyValueChanges<string, any>): void {
    if ( this.differInit ) { this.differInit = false; return; }
    // if ( !this.alreadyChanged ) { this.globalcom.changeMessage(JSON.stringify({type: 'needroute'})); }
    this.alreadyChanged = true;
  }

  save(): void {
    this.entityPrepare();
    this.entity.set().subscribe(
      (data: any) => {
        if ('ind' in data) {
          this.snackBar.open('Rapport is opgeslagen', '', {
            duration: 2000,
          });
        } else {
          this.snackBar.open('Er is een fout opgetreden bij het opslaan van het rapport.', '', {
            duration: 2000,
          });
          console.log('Save error', data);
        }
      }
    );
  }

  entityPrepare(): void {
    if ( this.reportValues.title ) { this.entity.rTitle = this.reportValues.title; }
    if ( this.reportValues.subtitle ) { this.entity.rSubtitle = this.reportValues.subtitle; }
    if ( this.reportValues.adr_street ) { this.entity.rAddrStreet = this.reportValues.adr_street; }
    if ( this.reportValues.adr_nr ) { this.entity.rAddrNr = this.reportValues.adr_nr; }
    if ( this.reportValues.adr_tv ) { this.entity.rAddrTv = this.reportValues.adr_tv; }
    if ( this.reportValues.adr_pcode ) { this.entity.rAddrPcode = this.reportValues.adr_pcode; }
    if ( this.reportValues.adr_city ) { this.entity.rAddrCity = this.reportValues.adr_city; }
    this.entity.jsonData = JSON.stringify({
      schematic: this.schematic,
      values: this.reportValues
    });
  }

  leave(): void {
    this.router.navigate(['main']);
  }

  print(): void {
    this.globalcom.changeMessage({
      type: 'open-repot-print',
      id: this.entity.ind
    });
  }

  settings(): void {
    this.globalcom.changeMessage({
      type: 'open-repot-settings'
    });
  }

}
