import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share, shareReplay } from 'rxjs/operators';
import {Router} from '@angular/router';
import {ErrorHandler} from './ErrorHandler';
import { GlobalCommService } from '../services/global-comm.service';
import { SessionLoginService } from '../services/session/session-login.service';

export class Report {
    ind: string;
    rTitle: string;
    rSubtitle: string;
    rAddrStreet: string;
    rAddrNr: string;
    rAddrTv: string;
    rAddrPcode: string;
    rAddrCity: string;
    jsonData: string;
    createdBy: string;
    createdByVname: string;
    createdTime: number;
    editedBy: string;
    editedByVname: string;
    editedTime: number;
    deleted: number;

    error: boolean;
    errors: any[] = [];

    isWorking: any;
    typeOfWork: string;

    usableFields: any = {
        ind: 'ind',
        r_title: 'rTitle',
        r_subtitle: 'rSubtitle',
        r_addr_street: 'rAddrStreet',
        r_addr_nr: 'rAddrNr',
        r_addr_tv: 'rAddrTv',
        r_addr_pcode: 'rAddrPcode',
        r_addr_city: 'rAddrCity',
        json_data: 'jsonData',
        created_by: 'createdBy',
        created_by_vname: 'createdByVname',
        created_time: 'createdTime',
        edited_by: 'editedBy',
        edited_by_vname: 'editedByVname',
        edited_time: 'editedTime',
        deleted: 'deleted'
    };

    constructor(private http: HttpClient, private router: Router,
                private globalComm: GlobalCommService,
                private session: SessionLoginService) {
        this.ind = '';
        this.rTitle = '';
        this.rSubtitle = '';
        this.rAddrStreet = '';
        this.rAddrNr = '';
        this.rAddrTv = '';
        this.rAddrPcode = '';
        this.rAddrCity = '';
        this.jsonData = '';
        this.createdBy = '';
        this.createdByVname = '';
        this.createdTime = 0;
        this.editedBy = '';
        this.editedByVname = '';
        this.editedTime = 0;
        this.deleted = 0;

        this.isWorking = false;
        this.typeOfWork = '';
        this.error = false;
    }

    reload(): Observable<any> {
        return this.get(this.ind);
    }

    reloadSelected(sel: any[]): Observable<any> {
        return this.get(this.ind, sel);
    }

    get(ind: string, sel?: any[]): Observable<any> {
        if ( this.isWorking !== false ) { return this.isWorking; }
        this.typeOfWork = 'load';
        this.ind = ind;
        const rv = this.http.get('/API/report/' + encodeURIComponent(this.ind) + '/',
                                 { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        rv.subscribe(
            (res: any) => {
                if ('ind' in res) {
                    this.error = false; this.errors = [];
                    if ( sel ) {
                        for (const key of sel) {
                            if (key in this.usableFields && key in res) {
                                this[this.usableFields[key]] = res[key];
                            } else {
                                console.log('Cant map Report.' + key);
                            }
                        }
                    } else {
                        for (const key in res) {
                            if (key in this.usableFields) {
                                this[this.usableFields[key]] = res[key];
                            } else {
                                console.log('Cant map Report.' + key);
                            }
                        }
                    }
                } else if ('length' in res && res.length === 0) {
                    this.router.navigate(['404-element-not-found']);
                } else if ('error' in res ) {
                    const eh = new ErrorHandler(this.router);
                    eh.handle(res.error);
                } else {
                    this.error = true; this.errors.push('Cannot load Report: ' + this.ind);
                }
            },
            () => { this.isWorking = false; this.error = true; this.errors.push('Cannot load Report: ' + this.ind); },
            () => { this.isWorking = false; }
        );
        return rv;
    }

    set(): Observable<any> {
        if ( this.isWorking !== false ) { return this.isWorking; }
        this.typeOfWork = 'save';
        const toSend = {};
        for (const fk of Object.keys(this.usableFields)) {

            toSend[fk] = this[this.usableFields[fk]];
        }
        let URL = '/API/report/';
        if ( this.ind !== '' ) { URL += encodeURIComponent(this.ind) + '/'; }
        this.isWorking = this.http.post(URL, toSend, { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        this.isWorking.subscribe(
            (res: any) => {
                if ('ind' in res) {
                    this.error = false; this.errors = [];
                    for (const key in res) {
                        if (key in this.usableFields) {
                            this[this.usableFields[key]] = res[key];
                        } else {
                            console.log('Cant map Report.' + key);
                        }
                    }
                } else {
                    this.error = true; this.errors.push('Cannot save Report.');
                }
                this.isWorking = false;
            },
            () => { this.isWorking = false; this.error = true; this.errors.push('Cannot save Report.'); },
            () => { this.isWorking = false; }
        );
        return this.isWorking;
    }

    delete(): Observable<any> {
        if ( this.isWorking !== false ) { return this.isWorking; }
        this.typeOfWork = 'delete';
        let URL = '/API/report/';
        if ( this.ind !== '' ) { URL += encodeURIComponent(this.ind) + '/'; }
        this.isWorking = this.http.delete(URL, { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        this.isWorking.subscribe(
            (res: any) => {
                if ('deleted' in res) {
                    // deleted
                } else {
                    this.error = true; this.errors.push('Cannot delete Report.');
                }
                this.isWorking = false;
            },
            () => { this.isWorking = false; this.error = true; this.errors.push('Cannot delete Report.'); },
            () => { this.isWorking = false; }
        );
        return this.isWorking;
    }
}
