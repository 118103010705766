import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-report-section-graphs',
  templateUrl: './report-section-graphs.component.html',
  styleUrls: ['./report-section-graphs.component.css']
})
export class ReportSectionGraphsComponent implements OnInit {

  keyName = 'graphs';

  itemSchema: any;
  @Input()
  set schema(val: any) { this.itemSchema = val; this.schemaInit(); }
  get schema(): any { return this.itemSchema; }

  itemValues: any;
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  public ngOnInit(): void {
  }

  schemaInit(): void {
  }

  valuesInit(): void {
  }

  modelChanged($event: any): void {
    this.sendMessage();
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

}
