import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalCommService } from '../../services/global-comm.service';

@Component({
  selector: 'app-report-print',
  templateUrl: './report-print.component.html',
  styleUrls: ['./report-print.component.css']
})
export class ReportPrintComponent implements OnInit, OnDestroy {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  constructor(public dialog: MatDialog, private globalcom: GlobalCommService) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }
  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }

  ngOnInit(): void {
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'open-repot-print':
          this.openDialog();
          break;
        default: break;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ReportPrintDialogComponent, {
      // width: '950px',
      // data: this.itemSchema
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.animal = result;
    });
  }

}

@Component({
  selector: 'app-report-print-dialog',
  templateUrl: 'dialog-report-print.component.html',
  styleUrls: ['./dialog-report-print.component.css']
})
export class ReportPrintDialogComponent implements  OnInit, OnDestroy {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  concept: boolean;

  constructor(
    public dialogRef: MatDialogRef<ReportPrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalcom: GlobalCommService) {
      this.concept = false;

      this.globalcomInit = true;
      this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
        this.globalcomMsg = message;
        if ( !this.globalcomInit ) { this.parseGlobalCom(); }
        this.globalcomInit = false;
      });
  }
  parseGlobalCom(): void {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doPrint(): void {
    this.globalcom.changeMessage({
      type: 'run-print',
      concept: this.concept
    });
    this.dialogRef.close();
  }

}
