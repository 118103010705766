
<div class="mcontainer">
    <h1 mat-dialog-title>Instellingen</h1>
    <div mat-dialog-content>
        <div class="reset-buttons">
            <button mat-stroked-button (click)="resetStartDefault('system')">Zet de elementen terug naar de standaard instelling</button>
            <button mat-stroked-button  (click)="resetStartDefault('hard')">Zet de elementen terug naar de oorspronkelijke basis instellingen</button>
        </div>
        <div class="group" *ngFor="let item of data.elements">
            <div class="group-name">
                <mat-form-field class="fw">
                    <mat-label>Groep naam</mat-label>
                    <input matInput [(ngModel)]="item.name">
                </mat-form-field>
                <button mat-button (click)="addGE(item.childs)">
                    <mat-icon>add</mat-icon> 
                </button>
                <button mat-button (click)="deleteG(item)">
                    <mat-icon>delete</mat-icon> 
                </button>
            </div>
            <div class="group-elements">
                <div *ngFor="let item1 of item.childs">
                    <mat-form-field class="aid">
                        <mat-label>Id</mat-label>
                        <input matInput [(ngModel)]="item1.key">
                    </mat-form-field>
                    <mat-form-field class="aname">
                        <mat-label>Element</mat-label>
                        <input matInput [(ngModel)]="item1.name">
                    </mat-form-field>
                    <mat-form-field class="adesc">
                        <mat-label>Handeling</mat-label>
                        <input matInput [(ngModel)]="item1.action">
                    </mat-form-field>
                    <mat-form-field class="aunit">
                        <mat-label>Eenheidsmaat</mat-label>
                        <input matInput [(ngModel)]="item1.unit">
                    </mat-form-field>
                    <button mat-button aria-label="Afsluiten" (click)="deleteL(item.childs,item1)">
                        <mat-icon>delete</mat-icon> 
                    </button>
                </div>
            </div>
        </div>
        <!-- -- --- -->
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Afsluiten</button>
        <button mat-button (click)="closeYes()" cdkFocusInitial>OK</button>
    </div>

    <div class="coverup" *ngIf="coverup">
        <div class="coverup-info">
            <span>Weet u het zeker, deze bewerking kan niet ongedaan gemaakt worden?</span>
            <div class="coverup-buttons">
                <button mat-stroked-button (click)="resetClose()">Bewerking afbreken</button>
                <button mat-stroked-button (click)="resetDo()" color="warn">Bewerking uitvoeren</button>
            </div>
        </div>
    </div>

</div>