<div class="bottom-view">
    <section>
        <mat-button-toggle-group [(ngModel)]="itemValues._config.reportViews" aria-label="reportViews" appearance="standard">
            <mat-button-toggle value="all">Toon alles</mat-button-toggle>
            <!--<mat-button-toggle value="main">Inleiding</mat-button-toggle>-->
            <mat-button-toggle value="ycosts">Gemiddelde jaarkosten</mat-button-toggle>
            <mat-button-toggle value="10ycosts">Tienjarenplan</mat-button-toggle>
            <!--<mat-button-toggle value="graphs">Grafieken</mat-button-toggle>
            <mat-button-toggle value="attachments">Bijlagen</mat-button-toggle>-->
        </mat-button-toggle-group>
    </section>
</div>