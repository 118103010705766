import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCommService } from '../../services/global-comm.service';

@Component({
  selector: 'app-report-section-tenyears-planing',
  templateUrl: './report-section-tenyears-planing.component.html',
  styleUrls: ['./report-section-tenyears-planing.component.css']
})
export class ReportSectionTenyearsPlaningComponent implements OnInit {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  keyName = 'plan10years';

  enYears: any[] = [];

  colspanNr: any = 13;

  dontShowMe: boolean;

  itemSchema: any = {};
  @Input()
  set schema(val: any) { this.itemSchema = val; this.schemaInit(); }
  get schema(): any { return this.itemSchema; }

  itemValues: any = {totals: {}, totalTotals: {}, reservedFundsDonation: {}};
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private globalcom: GlobalCommService) {
    this.dontShowMe = false;

    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'values-changed':
          this.yearsCycleInitLine(pMsg.data);
          break;
        default: break;
      }
    }
  }

  yearsCycleInitLine(item: any): void {
    if ( !this.itemValues.startYear ||
      isNaN(parseFloat(this.itemValues.startYear)) ) {
      return;
    }

    const endYear = Number(this.itemValues.startYear) + 9;
    const startYear = Number(this.itemValues.startYear);
    const key = item.key;
    let cycle = 0;
    let cycleenddate = 0;
    let mkey = '';
    let mm = 0;

    if ( !this.itemValues?.yearCosts[key].cycle ||
         !this.itemValues?.yearCosts[key].cycleenddate ||
         !this.itemValues?.yearCosts[key].total ||
         isNaN(parseFloat(this.itemValues?.yearCosts[key].cycle)) ||
         isNaN(parseFloat(this.itemValues?.yearCosts[key].cycleenddate)) ||
         isNaN(parseFloat(this.itemValues?.yearCosts[key].total)) ||
         parseFloat(this.itemValues?.yearCosts[key].cycle) < 1 ||
         parseFloat(this.itemValues?.yearCosts[key].cycleenddate) < 1 ||
         parseFloat(this.itemValues?.yearCosts[key].total) < 1
       ) {
      return;
    }

    if ( !this.itemValues?.yearCosts ) { return; }
    cycle = Number(this.itemValues?.yearCosts[key].cycle);
    cycleenddate = Number(this.itemValues?.yearCosts[key].cycleenddate);
    mm = startYear;
    this.itemValues.year10Costs[key].childs = {};
    // while (mm <= endYear) {
    //  mkey = 'y' + mm;
    //  this.itemValues.year10Costs[key].childs[mkey] = '';
    //  mm++;
    // }
    while ( cycleenddate <= endYear ) {
      if ( cycleenddate >= startYear ) {
        mkey = 'y' + cycleenddate;
        if ( !isNaN(parseFloat(this.itemValues?.yearCosts[key].total)) && parseFloat(this.itemValues?.yearCosts[key].total) > 0 ) {
          this.itemValues.year10Costs[key].childs[mkey] = this.itemValues?.yearCosts[key].total;
          this.recalculateCellChange(item, {key: mkey});
        }
      }
      cycleenddate += cycle;
    }
  }

  public ngOnInit(): void {
    this.genEnYears();
  }

  schemaInit(): void {
    this.initValuesStructure();
  }

  valuesInit(): void {
    if ( this.itemValues?.startYear !== '' ) {
      this.modelChangedStartYear();
    }
  }

  yearsCyclesInit(): void {
    if ( !this.itemSchema?.elements ) { return; }
    if ( !this.itemValues.startYear ||
      isNaN(parseFloat(this.itemValues.startYear)) ) {
      return;
    }
    const endYear = Number(this.itemValues.startYear) + 9;
    const startYear = Number(this.itemValues.startYear);
    let cycle = 0;
    let cycleenddate = 0;
    let mm = startYear;
    let mkey = '';
    const totals = {};
    while (mm <= endYear) {
      mkey = 'y' + mm;
      totals[mkey] = {};
      totals[mkey].val = 0;
      // totals[mkey].val_printable = this.fprint(0);
      mm++;
    }
    if ( !this.itemValues?.yearCosts ) { return; }
    for (let i = 0; i < this.itemSchema['elements'].length; i++ ) {
      for (let j = 0; j < this.itemSchema['elements'][i]['childs'].length; j++ ) {
        const key = this.itemSchema['elements'][i]['childs'][j].key;
        if ( !this.itemValues?.yearCosts[key] ) { continue; }
        if ( !this.itemValues?.yearCosts[key].cycle || this.itemValues?.yearCosts[key].cycle === '' ||
             isNaN(parseFloat(this.itemValues?.yearCosts[key].cycle)) ||
             parseFloat(this.itemValues?.yearCosts[key].cycle) < 1 ) { continue; }
        if ( !this.itemValues?.yearCosts[key].cycleenddate || this.itemValues?.yearCosts[key].cycleenddate === '' ||
             isNaN(parseFloat(this.itemValues?.yearCosts[key].cycleenddate)) ||
             parseFloat(this.itemValues?.yearCosts[key].cycleenddate) < 1 ) { continue; }
        cycle = Number(this.itemValues?.yearCosts[key].cycle);
        cycleenddate = Number(this.itemValues?.yearCosts[key].cycleenddate);
        mm = startYear;
        this.itemValues.year10Costs[key].childs = {};
        // while (mm <= endYear) {
        //  mkey = 'y' + mm;
        //  this.itemValues.year10Costs[key].childs[mkey] = '';
        //  mm++;
        // }
        let lineTotal = 0;
        while ( cycleenddate <= endYear ) {
          if ( cycleenddate >= startYear ) {
            mkey = 'y' + cycleenddate;
            if ( !isNaN(parseFloat(this.itemValues?.yearCosts[key].total)) && parseFloat(this.itemValues?.yearCosts[key].total) > 0 ) {
              this.itemValues.year10Costs[key].childs[mkey] = this.itemValues?.yearCosts[key].total;
              totals[mkey].val += Number(this.itemValues?.yearCosts[key].total);
              totals[mkey].val_printable = this.fprint(totals[mkey].val);
              lineTotal += Number(this.itemValues?.yearCosts[key].total);
            }
          }
          cycleenddate += cycle;
        }
        this.itemValues.year10Costs[key].total = lineTotal;
        this.itemValues.year10Costs[key].total_printable = this.fprint(lineTotal);
      }
    }
    this.itemValues.year10Costs.totals = totals;
    this.sumupTotalTotals();
  }

  modelChanged($event: any, item1: any, item2: any): void {
    this.recalculateCellChange(item1, item2);
    this.sendMessage();
  }

  numberWithCommas(x: any): string {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
  }

  fprint(val: any): string {
    const s = val.toString().split('.');
    let r = '';
    r += this.numberWithCommas(s[0]);
    if ( s[1] ) {
      r += ',';
      switch ( s[1].length ) {
        case 0: r += '00'; break;
        case 1: r += s[1] + '0'; break;
        case 2: r += s[1]; break;
        default: r += s[1].substr(0, 2); break;
      }
    } else {
      r += ',00';
    }
    return r;
  }

  modelChangedDR($event: any): void {
    // console.log($event, this.itemValues.year10Costs.reservedFundsDonation);
    let total = 0;
    for (let i = 0; i < this.enYears.length; i++) {
      if ( this.itemValues.year10Costs.reservedFundsDonation[this.enYears[i].key] &&
           !isNaN(parseFloat(this.itemValues.year10Costs.reservedFundsDonation[this.enYears[i].key])) &&
           parseFloat(this.itemValues.year10Costs.reservedFundsDonation[this.enYears[i].key])>0 ) {
        total += Number(this.itemValues.year10Costs.reservedFundsDonation[this.enYears[i].key]);
      }
    }
    if ( total > 0) {
      this.itemValues.year10Costs.reservedFundsDonation_total = total;
      this.itemValues.year10Costs.reservedFundsDonation_total_printable = this.fprint(total);
    }
    this.sumupTotalTotals();
  }

  recalculateCellChange(item1: any, item2: any): void {
    // row total
    let total = 0;
    Object.keys(this.itemValues.year10Costs[item1.key].childs).forEach((key: string) => {
      if ( !isNaN(parseFloat(this.itemValues.year10Costs[item1.key].childs[key]))) {
        total += Number(this.itemValues.year10Costs[item1.key].childs[key]);
      }
    });
    this.itemValues.year10Costs[item1.key].total = total;
    this.itemValues.year10Costs[item1.key].total_printable = this.fprint(total);
    // col total
    total = 0;
    for (let i = 0; i < this.itemSchema['elements'].length; i++ ) {
      for (let j = 0; j < this.itemSchema['elements'][i]['childs'].length; j++ ) {
        if ( this.itemValues.year10Costs[this.itemSchema['elements'][i]['childs'][j].key].childs[item2.key] &&
             !isNaN(parseFloat(this.itemValues.year10Costs[this.itemSchema['elements'][i]['childs'][j].key].childs[item2.key])) ) {
          total += Number(this.itemValues.year10Costs[this.itemSchema['elements'][i]['childs'][j].key].childs[item2.key]);
        }
      }
    }
    this.itemValues['year10Costs'].totals[item2.key] ??= {};
    this.itemValues['year10Costs'].totals[item2.key].val = total;
    this.itemValues['year10Costs'].totals[item2.key].val_printable = this.fprint(total);
    this.sumupTotalTotals();
  }

  modelChangedRfunds(): void {
    this.sumupTotalTotals();
  }

  modelChangedRfundsDonate(): void {
    this.itemValues['year10Costs'] ??= {totals: {}, totalTotals: {}, reservedFundsDonation: {}};
    if ( !isNaN(parseFloat(this.itemValues.year10Costs.reservedFundsDonation))) {
      this.itemValues.year10Costs.reservedFundsDonation_printable = this.fprint(Number(this.itemValues.year10Costs.reservedFundsDonation));
      this.itemValues.year10Costs.reservedFundsDonationSumUp_printable = this.fprint(Number(this.itemValues.year10Costs.reservedFundsDonation) * 9);
    }
    this.sumupTotalTotals();
  }

  sumupTotalTotals(): void {
    this.itemValues['year10Costs'] ??= {};
    this.itemValues['year10Costs']['totalTotals'] ??= {};
    this.itemValues['year10Costs']['reservedFundsDonation'] ??= {};
    for (let i = 0; i < this.enYears.length; i++) {
      this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key] = {};
      if ( i === 0 ) {
        this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val = 0;
        if ( !isNaN(this.itemValues?.year10Costs?.startingReservedFunds) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val += Number(this.itemValues?.year10Costs?.startingReservedFunds);
        }
        if ( !isNaN(this.itemValues?.year10Costs?.totals[this.enYears[i].key]?.val) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val -= Number(this.itemValues?.year10Costs?.totals[this.enYears[i].key]?.val);
        }
        if ( !isNaN(this.itemValues?.year10Costs?.reservedFundsDonation[this.enYears[i].key]) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val += Number(this.itemValues?.year10Costs?.reservedFundsDonation[this.enYears[i].key]);
        }
      } else {
        this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val = 0;
        if ( !isNaN(this.itemValues['year10Costs']['totalTotals'][this.enYears[i - 1].key].val) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val += Number(this.itemValues['year10Costs']['totalTotals'][this.enYears[i - 1].key].val);
        }
        if ( !isNaN(this.itemValues?.year10Costs?.totals[this.enYears[i].key]?.val) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val -= Number(this.itemValues?.year10Costs?.totals[this.enYears[i].key]?.val);
        }
        if ( !isNaN(this.itemValues?.year10Costs?.reservedFundsDonation[this.enYears[i].key]) ) {
          this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val += Number(this.itemValues?.year10Costs?.reservedFundsDonation[this.enYears[i].key]);
        }
      }
      this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val_printable = this.fprint(this.itemValues['year10Costs']['totalTotals'][this.enYears[i].key].val);
    }
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

  modelChangedStartYear(): void {
    this.genEnYears();
    this.yearsCyclesInit();
    this.sendMessage();
    //this.globalcom.changeMessage({
    //  type: 'start-year-changed'
    //});
  }

  genEnYears(): void {
    if ( !this.itemValues.startYear ||
          isNaN(parseFloat(this.itemValues.startYear)) ) {
      this.dontShowMe = true;
      return;
    }
    this.dontShowMe = false;
    this.enYears = [];
    let startY = Number(this.itemValues.startYear);
    for (let i = 0; i < 10; i++) {
      this.enYears.push({
        key: 'y' + startY,
        name: startY.toString()
      });
      startY++;
    }
    this.colspanNr = 13;
    this.initValuesStructure();
  }

  initValuesStructure(): void {
    if ( typeof this.itemValues['year10Costs'] === 'undefined' ) {
      this.itemValues['year10Costs'] = {totals: {}, totalTotals: {}, reservedFundsDonation: {}};
    }
    if ( typeof this.itemSchema['elements'] !== 'undefined' && typeof this.itemSchema['elements'] ) {
      for (let i = 0; i < this.itemSchema['elements'].length; i++ ) {
        for (let j = 0; j < this.itemSchema['elements'][i]['childs'].length; j++ ) {
          // itemValues.year10Costs[item1.key].childs[item2.key]
          if ( typeof  this.itemValues['year10Costs'][this.itemSchema['elements'][i]['childs'][j].key] === 'undefined' ) {
            this.itemValues['year10Costs'][this.itemSchema['elements'][i]['childs'][j].key] = {childs: {}};
          }
        }
      }
    }
  }

}
