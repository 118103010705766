<div class="blank-space-top"></div>

<app-report-section-main *ngIf="reportValues?._config?.reportViews === 'all' || reportValues?._config?.reportViews === 'main'" (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues" [parentID]="entity.ind"></app-report-section-main>
<app-report-section-yearly-costs *ngIf="reportValues?._config?.reportViews === 'all' || reportValues._config?.reportViews === 'ycosts'" (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues"></app-report-section-yearly-costs>
<app-report-section-tenyears-planing *ngIf="reportValues?._config?.reportViews === 'all' || reportValues?._config?.reportViews === '10ycosts'" (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues"></app-report-section-tenyears-planing>
<app-report-section-graphs *ngIf="reportValues?._config?.reportViews === 'all' || reportValues?._config?.reportViews === 'graphs'" (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues"></app-report-section-graphs>
<app-report-section-attachments *ngIf="reportValues?._config?.reportViews === 'all' || reportValues?._config?.reportViews === 'attachments'" (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues"></app-report-section-attachments>
<div class="blank-space"></div>

<app-report-view-switch (messageEvent)="reciveEvent($event)" [schema]="schematic" [values]="reportValues"></app-report-view-switch>

<mat-toolbar class="toolbar">
    <img src="/assets/logo.png">
    <span>MJOP 2021</span>
    <span class="spacer"></span>
    <button mat-button aria-label="Bewaar rapport" (click)="save()">
        <mat-icon>save</mat-icon> Bewaren
    </button>
    <button mat-button aria-label="Rapport afdrukken" (click)="print()">
        <mat-icon>print</mat-icon> Afdrukken
    </button>
    <button mat-button aria-label="Instellingen" (click)="settings()">
        <mat-icon>settings</mat-icon> 
    </button>
    <button mat-button aria-label="Afsluiten" (click)="leave()">
        <mat-icon>close</mat-icon> 
    </button>
</mat-toolbar>

<app-report-settings [schema]="schematic" (messageEvent)="reciveSchenaEvent($event)"></app-report-settings>
<app-report-print></app-report-print>
<app-report-print-popup></app-report-print-popup>

<div class="cover" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>