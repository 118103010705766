import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportMainViewComponent } from './report/report-main-view/report-main-view.component';
import { ReportSectionMainComponent } from './report/report-section-main/report-section-main.component';
import { ReportSectionYearlyCostsComponent } from './report/report-section-yearly-costs/report-section-yearly-costs.component';
import { ReportSectionTenyearsPlaningComponent } from './report/report-section-tenyears-planing/report-section-tenyears-planing.component';
import { ReportSectionGraphsComponent } from './report/report-section-graphs/report-section-graphs.component';
import { ReportSectionAttachmentsComponent } from './report/report-section-attachments/report-section-attachments.component';
import { ReportViewSwitchComponent } from './report/report-view-switch/report-view-switch.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MainViewComponent } from './main-view/main-view.component';
import { ReportSettingsComponent } from './settings/report-settings/report-settings.component';
import { DialogReportSettingsDialogComponent } from './settings/report-settings/report-settings.component';
import { ReportPrintComponent } from './settings/report-print/report-print.component';
import { ReportPrintDialogComponent } from './settings/report-print/report-print.component';
import { ReportFirstPageImgComponent } from './report/report-first-page-img/report-first-page-img.component';
import { ReportPrintPopupComponent } from './report/report-print-popup/report-print-popup.component';
import { ReportGlobalSettingsComponent, DialogGlobalReportSettingsDialogComponent } from './settings/report-global-settings/report-global-settings.component';

@NgModule({
  declarations: [
    AppComponent,
    ReportMainViewComponent,
    ReportSectionMainComponent,
    ReportSectionYearlyCostsComponent,
    ReportSectionTenyearsPlaningComponent,
    ReportSectionGraphsComponent,
    ReportSectionAttachmentsComponent,
    ReportViewSwitchComponent,
    LoginPageComponent,
    MainViewComponent,
    ReportSettingsComponent, DialogReportSettingsDialogComponent, ReportPrintComponent,
    ReportPrintDialogComponent,
    ReportFirstPageImgComponent,
    ReportPrintPopupComponent,
    ReportGlobalSettingsComponent, DialogGlobalReportSettingsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    MatButtonModule, MatCheckboxModule, MatIconModule, MatBottomSheetModule, MatListModule,
    MatInputModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule,
    MatAutocompleteModule, MatRadioModule, MatSelectModule, MatButtonToggleModule, MatToolbarModule,
    MatSnackBarModule, MatProgressSpinnerModule, MatDialogModule, MatGridListModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
