
<div class="main-div">
    <div class="mlabel">Gemiddelde jaarkosten</div>
    <table cellpadding="0" cellspacing="0">

        <tr class="head">
            <th>Element</th>
            <th>Handeling</th>
            <th>Laatst uitgevoerd</th>
            <th>Cyclus</th>
            <th>Urgentie</th>
            <th>Eenheidsprijs</th>
            <th>Eenheidsmaat</th>
            <th>Hoeveelheid</th>
            <th>Kosten totaal</th>
            <th>Kosten p/j</th>
        </tr>

        <tr><td colspan="10">&nbsp;</td></tr>

        <ng-container *ngFor="let item of itemSchema.elements">
            <tr>
                <td colspan="10" class="group-name">{{item.name}}</td>
            </tr>
            <tr *ngFor="let item1 of item.childs" class="nrow">
                <td>{{item1.name}}</td>
                <td>{{item1.action}}</td>
                <td>
                    <input type="number" class="cycle" [(ngModel)]="itemValues.yearCosts[item1.key].cycleenddate" (change)="modelChanged($event, item1, 'u')">
                </td>
                <td><input type="number" class="cycle" [(ngModel)]="itemValues.yearCosts[item1.key].cycle" (change)="modelChanged($event, item1, 'u')"></td>
                <td class="urg-{{itemValues.yearCosts[item1.key].urgency}}">
                    {{itemValues.yearCosts[item1.key].urgency_printable}}
                </td>
                <td>€&nbsp;<input type="number" class="unitprice" [(ngModel)]="itemValues.yearCosts[item1.key].unitprice" (change)="modelChanged($event, item1)"></td>
                <td>{{item1.unit}}</td>
                <td><input type="number" class="amount" [(ngModel)]="itemValues.yearCosts[item1.key].amount" (change)="modelChanged($event, item1)"></td>
                <td class="tright">
                    <span *ngIf="!itemValues.yearCosts[item1.key].total_printable"> - </span>
                    <span *ngIf="itemValues.yearCosts[item1.key].total_printable">€&nbsp;{{itemValues.yearCosts[item1.key].total_printable}}</span>
                </td>
                <td class="tright">
                    <span *ngIf="!itemValues.yearCosts[item1.key].totalpy_printable"> - </span>
                    <span *ngIf="itemValues.yearCosts[item1.key].totalpy_printable">€&nbsp;{{itemValues.yearCosts[item1.key].totalpy_printable}}</span>
                </td>
            </tr>
            <tr><td colspan="10">&nbsp;</td></tr>
        </ng-container>
        <tr class="sumrow"><td colspan="6">&nbsp;</td><td colspan="2">Benodigde dotatie per jaar</td><td>
            <span *ngIf="!itemValues.yearCostsTotalPrintable"> - </span>
            <span *ngIf="itemValues.yearCostsTotalPrintable">€&nbsp;{{itemValues.yearCostsTotalPrintable}}</span>

        </td></tr>
    </table>
</div>