import {Router} from '@angular/router';

export class ErrorHandler {
    constructor(private router: Router) {}

    handle(errorCode: any): void {
        switch (errorCode) {
            case 404: this.router.navigate(['404-element-not-found']); break;
            case 401: this.router.navigate(['401-access-not-allowed']); break;
            default: this.router.navigate(['500-unexpected-error']); break;
        }
    }
}
