<div class="uplbox" *ngIf="imageId == ''">
    klik hier om een afbeelding voor de eerste pagina te uploaden
    <input type="file" accept="image/x-png,image/gif,image/jpeg" (change)="fileUpload($event)">
</div>
<div class="uplbox" *ngIf="imageId != ''">
  <img src="/API/file/{{imageId}}/" />
  <button mat-button aria-label="Del rapport" (click)="runDel()">
    <mat-icon>delete</mat-icon>
</button>
  <i class="far fa-trash-alt farright" data-toggle="modal" data-target="#delIconModal"></i>
</div>
  
  <!--
  <div class="modal fade" id="delIconModal" tabindex="-1" role="dialog" aria-labelledby="delIconModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="delIconModalLabel">Icoon verwijderen</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Wilt u het icoon echt verwijderen?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="runDel()" data-dismiss="modal">Verwijderen</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">Annuleren</button>
        </div>
      </div>
    </div>
  </div>
  -->