import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-report-view-switch',
  templateUrl: './report-view-switch.component.html',
  styleUrls: ['./report-view-switch.component.css']
})
export class ReportViewSwitchComponent implements OnInit {

  keyName = 'view-switch';

  itemSchema: any = {};
  @Input()
  set schema(val: any) { this.itemSchema = val; }
  get schema(): any { return this.itemSchema; }

  itemValues: any = {};
  @Input()
  set values(val: any) { this.itemValues = val; this.initValues(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.initValues();
  }

  initValues(): void {
    this.itemValues._config ??= {};
    this.itemValues._config.reportViews ??= 'all';
  }

  modelChanged($event: any): void {
    this.sendMessage();
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

}
