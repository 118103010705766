<div class="main-section">
    <div class="spacered">
        <mat-form-field class="full-width">
            <mat-label>Rapporttitel</mat-label>
            <input matInput [(ngModel)]="itemValues.title" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>Ondertitel</mat-label>
            <input matInput [(ngModel)]="itemValues.subtitle" (change)="modelChanged($event)">
        </mat-form-field>
    </div>
    <div class="spacered">
        <div>Adres</div>
        <mat-form-field class="adr-street">
            <mat-label>Straat</mat-label>
            <input matInput [(ngModel)]="itemValues.adr_street" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="adr-nr">
            <mat-label>huisnr</mat-label>
            <input matInput [(ngModel)]="itemValues.adr_nr" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="adr-tv">
            <mat-label>toev.</mat-label>
            <input matInput [(ngModel)]="itemValues.adr_tv" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="adr-pcode">
            <mat-label>Postcode</mat-label>
            <input matInput [(ngModel)]="itemValues.adr_pcode" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="adr-city">
            <mat-label>Plaats</mat-label>
            <input matInput [(ngModel)]="itemValues.adr_city" (change)="modelChanged($event)">
        </mat-form-field>
    </div>
    <div class="spacered">
        <div>Postadres</div>
        <mat-form-field class="pa-postbus">
            <mat-label>Postbus</mat-label>
            <input matInput [(ngModel)]="itemValues.postbus" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="pa-postcode">
            <mat-label>Postcode</mat-label>
            <input matInput [(ngModel)]="itemValues.postbus_pcode" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field class="pa-city">
            <mat-label>Plaats</mat-label>
            <input matInput [(ngModel)]="itemValues.postbus_city" (change)="modelChanged($event)">
        </mat-form-field>
    </div>
    <div class="spacered">
        <mat-form-field>
            <mat-label>Bouwjaar</mat-label>
            <input matInput [(ngModel)]="itemValues.bouwjaar" (change)="modelChanged($event)">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Eenheden</mat-label>
            <input matInput [(ngModel)]="itemValues.eenheden" (change)="modelChanged($event)">
        </mat-form-field>
        <app-report-first-page-img *ngIf="parentID!=''" (messageEvent)="reciveEvent($event)" [values]="itemValues" [parentID]="parentID"></app-report-first-page-img>
        <!--<mat-form-field class="w25">
            <mat-label>Eindjaar</mat-label>
            <input matInput [(ngModel)]="itemValues.title" (change)="modelChanged($event)">
        </mat-form-field>-->
    </div>
    <div class="spacered">
        
    </div>
</div>