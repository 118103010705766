
<div class="main-div">
    <div class="mlabel">Tienjarenplan</div>
    <mat-form-field>
        <mat-label>Startjaar</mat-label>
        <input matInput [(ngModel)]="itemValues.startYear" (change)="modelChangedStartYear()">
    </mat-form-field>
    <div class="warning" *ngIf="dontShowMe">
        Voordat u kan starten met het programma vul hier dan eerst het startjaar in.
    </div>
    <table cellpadding="0" cellspacing="0" *ngIf="!dontShowMe">

        <tr class="head">
            <th>Element</th>
            <th>Cyclus</th>
            <th *ngFor="let item of enYears" class="yearhead">{{item.name}}</th>
            <th>Totaal</th>
        </tr>

        <tr><td [attr.colspan]="colspanNr">&nbsp;</td></tr>

        <ng-container *ngFor="let item of itemSchema.elements">
            <tr>
                <td [attr.colspan]="colspanNr" class="group-name">{{item.name}}</td>
            </tr>
            <tr *ngFor="let item1 of item.childs" class="nrow">
                <td>{{item1.name}}</td>
                <td><span *ngIf="itemValues.yearCosts && itemValues.yearCosts[item1.key]">{{itemValues.yearCosts[item1.key].cycle}}</span></td>
                <td *ngFor="let item2 of enYears">€&nbsp;<input type="number" class="price" [(ngModel)]="itemValues.year10Costs[item1.key].childs[item2.key]" (change)="modelChanged($event, item1, item2)"></td>
                <td>
                    <span *ngIf="!itemValues.year10Costs[item1.key].total_printable"> - </span>
                    <span *ngIf="itemValues.year10Costs[item1.key].total_printable">€&nbsp;{{itemValues.year10Costs[item1.key].total_printable}}</span>
                </td>
            </tr>
            <tr><td [attr.colspan]="colspanNr">&nbsp;</td></tr>
        </ng-container>

        <tr class="sumrow1"><td colspan="2">Beginstand reservefonds</td><td>
            €&nbsp;<input type="number" class="price" [(ngModel)]="itemValues.year10Costs.startingReservedFunds" (change)="modelChangedRfunds()">
        </td></tr>
        <tr class="sumrow2"><td colspan="2">Opname vanuit reservefonds</td><td *ngFor="let item of enYears">
            <span *ngIf="!(itemValues?.year10Costs?.totals[item.key]?.val_printable)"> - </span>
            <span class="fred" *ngIf="(itemValues?.year10Costs?.totals[item.key]?.val_printable)">€&nbsp;-{{itemValues.year10Costs.totals[item.key].val_printable}}</span>
        </td><td></td></tr>
        <tr class="sumrow3"><td colspan="2">Dotatie aan reservefonds</td>
            <td *ngFor="let item2 of enYears">€&nbsp;<input type="number" class="price" [(ngModel)]="itemValues.year10Costs.reservedFundsDonation[item2.key]" (change)="modelChangedDR($event)"></td>
            <td>
                <span *ngIf="!itemValues.year10Costs.reservedFundsDonation_total_printable"> - </span>
                <span *ngIf="itemValues.year10Costs.reservedFundsDonation_total_printable">€&nbsp;{{itemValues.year10Costs.reservedFundsDonation_total_printable}}</span>
            </td>
            <!--
            <td *ngFor="let item of enYears; let first = first;">
            <span *ngIf="first">
                €&nbsp;<input type="number" class="price" [(ngModel)]="itemValues.year10Costs.reservedFundsDonation" (change)="modelChangedRfundsDonate()">
            </span>
            <span *ngIf="!first">
                <span *ngIf="!(itemValues?.year10Costs?.reservedFundsDonation_printable)"> - </span>
                <span *ngIf="(itemValues?.year10Costs?.reservedFundsDonation_printable)">€&nbsp;{{itemValues?.year10Costs?.reservedFundsDonation_printable}}</span>    
            </span>
            </td>
            <td>
            <span *ngIf="!(itemValues?.year10Costs?.reservedFundsDonationSumUp_printable)"> - </span>
            <span *ngIf="(itemValues?.year10Costs?.reservedFundsDonationSumUp_printable)">€&nbsp;{{itemValues?.year10Costs?.reservedFundsDonationSumUp_printable}}</span>    
            </td>
            -->
        </tr>
        <tr class="sumsumrow"><td colspan="2">Stand reservefonds ultimo boekjaar</td><td *ngFor="let item of enYears">
            <span *ngIf="!(itemValues?.year10Costs?.totalTotals) || !(itemValues?.year10Costs?.totalTotals[item.key]?.val_printable)"> - </span>
            <span *ngIf="(itemValues?.year10Costs?.totalTotals) && (itemValues?.year10Costs?.totalTotals[item.key]?.val_printable)">€&nbsp;{{itemValues.year10Costs.totalTotals[item.key].val_printable}}</span>
        </td></tr>

    </table>
</div>