import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-section-attachments',
  templateUrl: './report-section-attachments.component.html',
  styleUrls: ['./report-section-attachments.component.css']
})
export class ReportSectionAttachmentsComponent implements OnInit {

  keyName = 'attachmetns';

  itemSchema: any;
  @Input()
  set schema(val: any) { this.itemSchema = val; this.schemaInit(); }
  get schema(): any { return this.itemSchema; }

  itemValues: any;
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private snackBar: MatSnackBar) {
  }

  public ngOnInit(): void {
  }

  schemaInit(): void {
  }

  valuesInit(): void {
  }

  modelChanged($event: any): void {
    this.sendMessage();
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

  fileChange(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
        for ( let i = 0; i < fileList.length; i++ ) {
          const file = fileList[i];
          if ((file.size / 1048576) <= 25) {
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('rid', this.itemValues.ind);
          } else {
            this.snackBar.open('Bestandsgrootte is te groot.', '', {
              duration: 2000,
            });
          }
        }
    }
  }

}
