import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { share, shareReplay } from 'rxjs/operators';
import { SessionLoginService } from '../../services/session/session-login.service';

@Component({
  selector: 'app-report-first-page-img',
  templateUrl: './report-first-page-img.component.html',
  styleUrls: ['./report-first-page-img.component.css']
})
export class ReportFirstPageImgComponent implements OnInit {

  keyName = 'maindata-fpimage';

  @Input() parentID: string;

  imageId: string;
  @Input() set image(val: string) {
    this.imageId = val;
    this.setImage();
  }
  get image(): string {
    return this.imageId;
  }

  @Output() messageEvent = new EventEmitter<any>();

  itemValues: any = {};
  @Input()
  set values(val: any) { this.itemValues = val; this.valuesInit(); }
  get values(): any { return this.itemValues; }

  constructor(private http: HttpClient,
              private session: SessionLoginService) {
    this.imageId = '';
  }

  valuesInit(): void {
    if ( this.itemValues['__firstPageImage'] ) {
      this.imageId = this.itemValues['__firstPageImage'];
    }
  }

  modelChanged($event: any): void {
    this.sendMessage();
  }

  sendMessage(): void {
    this.messageEvent.emit({value: this.itemValues, key: this.keyName});
  }

  ngOnInit(): void {
  }

  setImage(): void {
  }

  runDel(): void {
    this.http.delete('/API/file/' + encodeURIComponent(this.imageId) + '/')
      .subscribe((res: any) => {
        this.imageId = '';
      });
  }

  fileUpload(ev: any): void {
    // TODO: add progress bar for upload
    if ( ev.target.files.length > 0 ) {
      const file = ev.target.files[0];
      const toSend = {};
      toSend['parentID'] = this.parentID;
      toSend['name'] = file.name;
      toSend['size'] = file.size;
      toSend['mime'] = file.type;
      toSend['stype'] = 'fp';
      toSend['body'] = '';
      const reader = new FileReader();
      reader.onload = (e) => {
        toSend['body'] = reader.result;
        const URL = '/API/file/';
        const rv = this.http.post(URL, toSend, { withCredentials: true, headers: this.session.getSessionHeaders() }).pipe(shareReplay());
        rv.subscribe(
          (res: any) => {
            if ('ind' in res) {
              this.imageId = res.ind;
              if ( this.itemValues ) {
                this.itemValues['__firstPageImage'] = res.ind;
              }
            }
          }
        );
      };
      reader.readAsDataURL(file);
    }
  }

}
