import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  inDB: any;
  openP: any;

  constructor() {
    this.inDB = false;
    this.openP = false;
    let request: any;
    if ( !self.indexedDB ) {
      console.log('>>>>>>>>>>>>>>>>>>> NO INDEXED DB SUPPORT. OFFLINE WORK NOT POSSIBLE');
    } else {
      this.openP = new Promise((resolve, reject) => {
        request = self.indexedDB.open('mjop2021', 2);
        request.onupgradeneeded = (event: any) => {
          // creating database
          let db: any;
          db = event.target.result;
          console.log('DB upgrading', event);
          if ( event.oldVersion < 2 ) {
            if (db.objectStoreNames.contains('users')) { db.deleteObjectStore('users'); }
            if (db.objectStoreNames.contains('params')) { db.deleteObjectStore('params'); }
            if (db.objectStoreNames.contains('globals')) { db.deleteObjectStore('globals'); }
            db.createObjectStore('users', { keyPath: 'usr' });
            db.createObjectStore('params', { keyPath: ['usr', 'pname']});
            db.createObjectStore('globals', { keyPath: 'pname'});
          }
          console.log('db updated');
        };
        request.onerror = (event: any) => {
          console.log('>>>>>>>>>>>>>>>>>>> NO INDEXED DB SUPPORT. OFFLINE WORK NOT POSSIBLE');
          reject(request.error);
        };
        request.onsuccess = (event: any) => {
          this.inDB = event.target.result;
          console.log('db connected');
          resolve(request.result);
        };
      });
    }
  }

  dbSet(where: string, what: any): void {
    const transaction = this.inDB.transaction([where], 'readwrite');
    transaction.objectStore(where).put(what);
    transaction.commit();
  }

  dbGet(where: string, what: any): Promise<any> {
    const transaction = this.inDB.transaction([where], 'readwrite');
    const getreq = transaction.objectStore(where).get(what);
    transaction.commit();
    return getreq;
  }

  dbDel(where: string, what: any): Promise<any> {
    const transaction = this.inDB.transaction([where], 'readwrite');
    const getreq = transaction.objectStore(where).delete(what);
    transaction.commit();
    return getreq;
  }
}
