import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalCommService } from '../../services/global-comm.service';

@Component({
  selector: 'app-report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.css']
})
export class ReportSettingsComponent implements  OnInit, OnDestroy {

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  itemSchema: any = {};
  @Input()
  set schema(val: any) { this.itemSchema = JSON.parse(JSON.stringify(val)); }
  get schema(): any { return this.itemSchema; }

  constructor(public dialog: MatDialog, private globalcom: GlobalCommService) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }
  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }

  parseGlobalCom(): void {
    let pMsg: any;
    try {
      pMsg = JSON.parse(this.globalcomMsg);
    } catch (e) {
      return;
    }
    if ( pMsg === null ) { return; }
    if ( 'type' in pMsg ) {
      switch (pMsg.type) {
        case 'open-repot-settings':
          this.openDialog();
          break;
        default: break;
      }
    }
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogReportSettingsDialogComponent, {
      width: '950px',
      data: this.itemSchema
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.animal = result;
    });
  }

}

@Component({
  selector: 'app-dialog-report-settings-dialog',
  templateUrl: 'dialog-report-settings-dialog.html',
  styleUrls: ['./dialog-report-settings-dialog.component.css']
})
export class DialogReportSettingsDialogComponent implements  OnInit, OnDestroy {

  predefOptions: string[] = ['m²', 'm', 'm1', 'per stuk', 'per app.'];

  coverup: boolean;

  val: any;
  resetType: string;

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogReportSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalcom: GlobalCommService) {
      this.coverup = false;
      this.resetType = '';

      this.globalcomInit = true;
      this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
        this.globalcomMsg = message;
        if ( !this.globalcomInit ) { this.parseGlobalCom(); }
        this.globalcomInit = false;
      });
  }

  parseGlobalCom(): void {
  }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resetStartDefault(type: string): void {
    this.coverup = true;
    this.resetType = type;
  }
  resetClose(): void {
    this.coverup = false;
    this.resetType = '';
  }

  resetDo(): void {
    switch ( this.resetType ) {
      default: break;
      case 'system':
        this.globalcom.changeMessage({
          type: 'reset-to-system'
        });
        break;
      case 'hard':
        this.globalcom.changeMessage({
          type: 'reset-to-hardreset'
        });
        break;
    }
    this.resetClose();
    this.dialogRef.close();
  }

  closeYes(): void {
    this.globalcom.changeMessage({
      type: 'reset-to-new-setup',
      settings: this.data
    });
    this.dialogRef.close();
  }  

  addGE(item: any): void {
    item.push({key: '', name: '', action: '', unit: ''});
  }

  deleteG(item: any): void {
    const index = this.data.elements.indexOf(item);
    if ( index !== -1 ) {
      this.data.elements.splice(index, 1);
    }
  }

  deleteL(item: any, item1: any): void {
    const index = item.indexOf(item1);
    if ( index !== -1 ) {
      item.splice(index, 1);
    }
  }

}
