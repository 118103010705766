<div class="spacer-top"></div>
<div class="report-list">
    <span class="report-list-title">Lijst met rapporten</span>
    <div class="report-list-items">
        <div class="no-items" *ngIf="!reportsList.length">Geen rapporten beschikbaar</div>
        <div >
        <table class="dtable" *ngIf="reportsList.length">
            <tr class="items">
                <th>Rapporttitel / Ondertitel</th>
                <th>Straat</th>
                <th>Huisnr toev.</th>
                <th>Postcode</th>
                <th>Plaats</th>
                <th>&nbsp;</th>
            </tr>
            <tr class="items" *ngFor="let item of reportsList" (click)="routeTo(item)">
                <td class="tittles">
                    <span>{{item.r_title}}</span><br>
                    <span class="subtitle">{{item.r_subtitle}}</span>
                </td>
                <td class="street">{{item.r_addr_street}}</td>
                <td class="nr">{{item.r_addr_nr}} {{item.r_addr_tv}}</td>
                <td class="pcode">{{item.r_addr_pcode}}</td>
                <td class="city">{{item.r_addr_city}}</td>
                <td class="action"></td>
            </tr>
        </table>
        </div>
    </div>
</div>

<mat-toolbar class="toolbar">
    <img src="/assets/logo.png">
    <span>MJOP 2021</span>
    <span class="spacer"></span>
    <button mat-button aria-label="Maak een nieuw rapport" routerLink="/r/new">
        <mat-icon>add</mat-icon> start een nieuw rapport
    </button>
    <button mat-button (click)="runSettings()">
        <mat-icon>settings</mat-icon> 
    </button>
    <button mat-button (click)="logout()">
        <mat-icon>logout</mat-icon> 
    </button>
</mat-toolbar>

<app-report-global-settings></app-report-global-settings>