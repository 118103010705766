import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { GlobalCommService } from '../services/global-comm.service';
import { SessionLoginService } from '../services/session/session-login.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../services//storage/storage.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css']
})
export class MainViewComponent implements OnInit, OnDestroy {

  reportsList: any[] = [];

  globalcomMsg: string;
  globalcomSub: any;
  globalcomInit: boolean;

  constructor(private router: Router, private session: SessionLoginService,
              private globalcom: GlobalCommService, private http: HttpClient,
              private storage: StorageService ) {
    this.globalcomInit = true;
    this.globalcomSub = this.globalcom.currentMessage.subscribe((message) => {
      this.globalcomMsg = message;
      if ( !this.globalcomInit ) { this.parseGlobalCom(); }
      this.globalcomInit = false;
    });
  }

  ngOnDestroy(): void {
    this.globalcomSub.unsubscribe();
  }
  
  parseGlobalCom(): void {
    // implement if needed
  }

  ngOnInit(): void {
    if ( !this.session.isLogged() ) {
      this.router.navigate(['login']);
      return;
    }
    this.loadProjects();
  }

  routeTo(item: any): void {
    this.router.navigate(['r/' + item.ind]);
  }

  loadProjects(): void {
    const URL = '/API/report/';
    this.http.get(URL, { withCredentials: true, headers: this.session.getSessionHeaders() }).subscribe(
      (res: any) => {
        this.reportsList = [];
        if ('length' in res) {
          this.reportsList = JSON.parse(JSON.stringify(res));
        }
      }
    );
  }

  logout(): void {
    const l1: any = this.storage.dbDel('users', this.session.user);
    l1.onsuccess = () => {
      const l2: any = this.storage.dbDel('globals', 'last_usr');
      l2.onsuccess = () => {
        this.session.doLogout();
        this.session.user = false;
        this.session.sKey = false;
        this.router.navigate(['login']);
      }
    }
  }

  runSettings(): void {
    this.globalcom.changeMessage({
      type: 'open-global-repot-settings'
    });
  }

}
