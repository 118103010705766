import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';
// import { AES, SHA1, enc as CryptoJSenc } from 'crypto-js';
import { StorageService } from '../storage/storage.service';
import { GlobalCommService } from '../global-comm.service';

@Injectable({
  providedIn: 'root'
})

export class SessionLoginService {
  isPostWorking: any;
  isLoginWorking: any;
  sKey: any;
  user: any;

  error: boolean;
  errors: any[];

  constructor(private router: Router, private http: HttpClient, private Storage: StorageService,
              private globalComm: GlobalCommService) {
    this.sKey = false;
    this.user = false;
    this.isPostWorking = false;
    this.isLoginWorking = false;
    this.error = false;
    this.errors = [];
  }

  checkForInit(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const lastUsr: any = this.Storage.dbGet('globals', 'last_usr');
      lastUsr.onsuccess = () => {
        if ( !lastUsr.result ) { return; }
        const uData: any = this.Storage.dbGet('users', lastUsr.result.usr);
        uData.onsuccess = () => {
          if ( !uData.result ) { return; }
          this.sKey = uData.result.token;
          this.user = uData.result.usr;
          resolve(uData.result);
        };
      };
    });
    return promise;
  }

  getSessionHeaders(): any {
    return {
        'X-TOKEN': this.user + ':' + this.sKey,
    };
  }

  doLogout(): Observable<any> {
    const URL = '/API/logout/' + this.user + '/' + this.sKey + '/';
    const rq = this.http.get(URL).pipe(share());
    rq.subscribe((res: any) => { console.log(res) });
    return rq 
  }

  doLogin(uname: string, pwd: string, staylogged: boolean): void {
    if ( this.isLoginWorking ) { return; }
    const toSend = {};
    toSend['usr'] = uname;
    toSend['pwd'] = pwd;
    const URL = '/API/login/';
    this.isLoginWorking = this.http.post(URL, toSend).pipe(share());
    this.isLoginWorking.subscribe(
      // data
      (res: any) => {
          this.isLoginWorking = false;
          if ( res.result === 'OK' ) {
            this.user = res.usr;
            this.sKey = res.skey;
            // const sKeyEnc = AES.encrypt(this.sKey, pwd).toString();
            // const sUsrEnc = AES.encrypt(this.user, pwd).toString();
            // const usrEnc = SHA1(this.user).toString();
            // const pwdEnc = SHA1(pwd).toString();
            this.Storage.dbSet('users', {usr: res.usr, token: res.skey});
            this.Storage.dbSet('globals', {pname: 'last_usr', usr: res.usr});
            this.globalComm.changeMessage({type: 'login-success'});
            this.router.navigate(['main']);
          } else {
            this.globalComm.changeMessage({type: 'login-error'});
          }
      },
      // error
      (a: any) => {
        this.isLoginWorking = false;
        // logging without network
        console.log('>>> NET IS DEAD! <<<');
        /*
        const usrEnc = SHA1(uname).toString();
        const pwdEnc = SHA1(pwd).toString();
        const getreq: any = this.CStorage.dbGet('users', usrEnc);
        getreq.onsuccess = () => {
          if ( getreq.result ) {
            if ( getreq.result.pwd === pwdEnc ) {
              this.user = AES.decrypt(getreq.result.susr, pwd).toString(CryptoJSenc.Utf8);
              this.sKey = AES.decrypt(getreq.result.skey, pwd).toString(CryptoJSenc.Utf8);
              this.globalComm.changeMessage({type: 'login-success-offline'});
              this.router.navigate(['main']);
            }
          }
        };
        */
      }
    );
    return this.isLoginWorking;
  }

  testLoggedIn(): void {
    if ( !this.isLogged() ) { this.router.navigate(['login']); }
  }

  isLogged(): boolean {
    return this.user !== false;
  }

  // server passing

  postToServer(URL: any, data: any): Observable<object> {
    if ( this.isPostWorking !== false ) { return this.isPostWorking; }
    const toSend = JSON.parse(JSON.stringify(data));
    this.isPostWorking = this.http.post(URL, toSend, { withCredentials: true, headers: {'X-TBC-SKEY': this.sKey} }).pipe(shareReplay());
    this.isPostWorking.subscribe(
      (res: any) => {
        this.isPostWorking = false;
        this.error = false;
        this.errors = [];
      },
      () => {
        this.isPostWorking = false;
        this.error = true;
        this.errors.push('Cannot save to server.');
      },
      () => {
        this.isPostWorking = false;
      }
    );
    return this.isPostWorking;
  }

  getFromServer(URL: any): Observable<object> {
    const rv = this.http.get(URL, { withCredentials: true, headers: {'X-TBC-SKEY': this.sKey} }).pipe(shareReplay());
    rv.subscribe(
      (res: any) => { this.error = true; this.errors = []; },
      () => { this.error = true; this.errors.push('Cannot load from server'); },
      () => { }
    );
    return rv;
  }
}
