import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { SessionLoginService } from '../services/session/session-login.service';
import { StorageService } from '../services/storage/storage.service';
import { GlobalCommService } from '../services/global-comm.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  login: string;
  pass: string;

  constructor(private route: ActivatedRoute, private router: Router, private sessionLogin: SessionLoginService,
              private globalComm: GlobalCommService, private storage: StorageService) {
    this.login = '';
    this.pass = '';
  }

  ngOnInit(): void {
    if ( this.sessionLogin.isLogged() ) {
      this.router.navigate(['main']);
    }
    this.storage.openP.then(
      () => {
        this.sessionLogin.checkForInit().then((res) => {
          if ( this.sessionLogin.isLogged() ) {
            this.router.navigate(['main']);
          }
        });
      }
    );
    // here checking for update
  }

  doLogin(): void {
    this.sessionLogin.doLogin(this.login, this.pass, false);
  }

}
