import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalCommService {

  private messageSource = new BehaviorSubject('{"type": "default"; "msg": "json default mgs"}');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: any): void {
    this.messageSource.next(JSON.stringify(message));
  }

}
